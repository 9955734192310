import { Text, Box, AspectRatio, Embed } from "theme-ui";
import pdfCmrRecruitmentDeck from "../../../../content/posts/_portfolio/cornell-mars-rover/cmr-recruitment-deck.pdf";
import * as React from 'react';
export default {
  Text,
  Box,
  AspectRatio,
  Embed,
  pdfCmrRecruitmentDeck,
  React
};