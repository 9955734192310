import { Text, Box, AspectRatio, Embed, Grid, Image } from "theme-ui";
import { FooterLogo } from "@shetharp/gatsby-theme-polaroid";
import pdfCraterPitchDeck from "../../../../content/posts/_portfolio/crater/crater-pitch-deck.pdf";
import imgCraterAppCollaborate from "../../../../content/posts/_portfolio/crater/crater-app-collaborate.jpg";
import imgCraterAppCreate from "../../../../content/posts/_portfolio/crater/crater-app-create.jpg";
import imgCraterAppDiscover from "../../../../content/posts/_portfolio/crater/crater-app-discover.jpg";
import imgCraterAppRecord from "../../../../content/posts/_portfolio/crater/crater-app-record.jpg";
import imgCraterFoundersGrid1 from "../../../../content/posts/_portfolio/crater/crater-founders-grid-1.png";
import imgCraterFoundersGrid2 from "../../../../content/posts/_portfolio/crater/crater-founders-grid-2.png";
import imgCraterFounders from "../../../../content/posts/_portfolio/crater/crater-founders.jpg";
import * as React from 'react';
export default {
  Text,
  Box,
  AspectRatio,
  Embed,
  Grid,
  Image,
  FooterLogo,
  pdfCraterPitchDeck,
  imgCraterAppCollaborate,
  imgCraterAppCreate,
  imgCraterAppDiscover,
  imgCraterAppRecord,
  imgCraterFoundersGrid1,
  imgCraterFoundersGrid2,
  imgCraterFounders,
  React
};